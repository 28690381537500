import React from "react";
import Particles from "react-particles-js";

export default () => (
  <Particles
    className="particlesJS"
    params={{
      particles: {
        number: {
          value: 256,
          density: {
            enable: true,
            value_area: 1600
          }
        },
        color: {
          value: "#250404"
        },
        shape: {
          type: "circle",
          stroke: {
            width: 1,
            color: "#ff8888"
          },
          polygon: {
            nb_sides: 7
          },
          image: {
            src: "img/github.svg",
            width: 100,
            height: 100
          }
        },
        opacity: {
          value: 0.4008530152163807,
          random: false,
          anim: {
            enable: false,
            speed: 1,
            opacity_min: 0.1,
            sync: false
          }
        },
        size: {
          value: 1.5,
          random: true,
          anim: {
            enable: false,
            speed: 40,
            size_min: 0.1,
            sync: false
          }
        },
        line_linked: {
          enable: true,
          distance: 88,
          color: "#e174a7",
          opacity: 1,
          width: 0.6413648243462091
        },
        move: {
          enable: true,
          speed: 2,
          direction: "none",
          random: false,
          straight: false,
          out_mode: "out",
          bounce: false,
          attract: {
            enable: false,
            rotateX: 600,
            rotateY: 1200
          }
        }
      },
      interactivity: {
        detect_on: "window",
        events: {
          onhover: {
            enable: false,
            mode: "grab"
          },
          onclick: {
            enable: true,
            mode: "push"
          },
          resize: true
        },
        modes: {
          grab: {
            distance: 256,
            line_linked: {
              opacity: 0.678
            }
          },
          bubble: {
            distance: 400,
            size: 40,
            duration: 2,
            opacity: 8,
            speed: 3
          },
          repulse: {
            distance: 100,
            duration: 0.4
          },
          push: {
            particles_nb: 4
          },
          remove: {
            particles_nb: 2
          }
        }
      },
      retina_detect: true
    }}
  />
);
