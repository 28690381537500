import React, { Component } from 'react';
import ParticleComponent from './components/ParticleComponent';
import TeamCard from './components/TeamCard';
import { library } from '@fortawesome/fontawesome-svg-core'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTwitter, faInstagram } from '@fortawesome/free-brands-svg-icons'
import { faFilePdf, faWifi, faMask, faSignal, faMobile } from '@fortawesome/free-solid-svg-icons'
library.add(faFilePdf);

class App extends Component {
  render() {
    return (
      <div className="primaryWrapper">
        <section className="landingView">
          <img className="zontikLogo" src="./img/dark_logoWordMarkVer2.svg" alt="Zontik Logo"/>
          <h1>A world of<br/>long range<br/>wireless IoT</h1>
        </section>
        <section>
          <h4>What We Make</h4>
          <div className="productCardContainer">
            <div className='productCard'>
              <div className="productCardHeader">
                <h5>TrailSpot</h5>
                &ensp;/&ensp;
                <a
                  href="https://trailspot.io/"
                  target="_blank"
                  rel="noopener noreferrer">www.trailspot.io</a>
              </div>
              <div className="productCardBody">
                <p><FontAwesomeIcon icon={faWifi}/>Long-range mesh WiFi</p>
                <p><FontAwesomeIcon icon={faMask}/>Stealth WiFi/Bluetooth logger</p>
                <p><FontAwesomeIcon icon={faMobile}/>Client connectivity for application enablement</p>
                <p><FontAwesomeIcon icon={faSignal}/>Offers connectivity in locations outside of Cellular coverage</p>
                <button>
                  <a href="files/zontik_deck_public.pdf" target="_blank">
                  Download our pitch deck
                    <FontAwesomeIcon icon={faFilePdf}/>
                  </a>
                </button>
              </div>
            </div>
          </div> 
        </section>
        <section>
          <h4>Who We Are</h4>
          <div className="teamCardContainer">
            <TeamCard
              name="Ivan Polchenko"
              title="Co-Founder, CEO"
              imageSource="./img/team_IvanPolchenko.jpg"/>
            <TeamCard
              name="Adonis Rebano"
              title="Co-Founder, COO"
              imageSource="./img/team_AdonisMeridus.jpg"/>
            <TeamCard
              name="Shun Miyaki"
              title="Front-end Developer"
              imageSource="./img/team_ShunMiyaki.jpg"/>
          </div>
        </section>
        <footer>
          <div className="socialIcons">
            <a href="https://www.twitter.com/teamzontik" target="_blank" rel="noopener noreferrer">
              <FontAwesomeIcon icon={faTwitter}/>
            </a>
            <a href="https://www.instagram.com/teamzontik" target="_blank" rel="noopener noreferrer">
              <FontAwesomeIcon icon={faInstagram}/>
            </a>
          </div>
          <p>{new Date().getFullYear()}&copy; Zontik Communications Ltd.</p>
        </footer>
        <ParticleComponent/>
      </div>
    );
  }
}

export default App;