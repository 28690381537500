import React, { Component } from 'react';

class TeamCard extends Component {
  render() {
    const {imageSource, name, title} = this.props;
    return (
      <div className="teamCard">
        <img className="teamCardAvatar" src={imageSource} alt={name}/>
        <p className="name">{name}</p>
        <p className="title">{title}</p>
      </div>
    );
  }
}

export default TeamCard;
